import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

function CommonRoute({ component: Component, ...rest }) {
  const accessToken = localStorage.getItem('accessToken');
  const { path } = rest;
  const render = (props) => <Component {...props} />;
  if (path === '/' && accessToken) {
    return <Redirect to="/web-admin/record" />;
  }
  return <Route {...rest} render={render} />;
}

CommonRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default CommonRoute;
