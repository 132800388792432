import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Dropdown, Menu } from 'antd';
import { Flex } from 'baseComponents/Layout';
import SideMenu from './components/SideMenu';

const { Header, Content } = Layout;

const Title = styled.span`
  font-size: 18px;
  color: white;
  margin-left: 16px;
`;

const UserBlock = styled.div`
  height: 100%;
  font-size: 16px;
  color: white;
  cursor: pointer;
  user-select: none;
`;

function MainLayout({ children }) {
  const userName = localStorage.getItem('userName');
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Flex
          px="24px"
          alignItems="center"
          justifyContent="space-between"
          height={64}
        >
          <Flex>
            <Title>PAMO Operation System</Title>
          </Flex>
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item key="logout" onClick={logout}>
                  登出
                </Menu.Item>
              </Menu>
            )}
            trigger={['click']}
          >
            <UserBlock>{userName}</UserBlock>
          </Dropdown>
        </Flex>
      </Header>
      <Layout className="site-layout" style={{ marginLeft: 256 }}>
        <SideMenu />
        <Content style={{ padding: '24px 16px 0', overflow: 'scroll' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
