import React from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { Flex } from 'baseComponents/Layout';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideMenu = () => {
  const accessToken = localStorage.getItem('accessToken');
  const decoded = jwtDecode(accessToken);
  const { role } = decoded.data;
  const history = useHistory();
  const onSelect = (item) => {
    history.push(item.key);
  };

  return (
    <Sider
      width={256}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      <Flex height="100%" flexDirection="column">
        <Menu
          onClick={onSelect}
          style={{ flex: 1 }}
          defaultSelectedKeys={[history.location.pathname]}
          defaultOpenKeys={[history.location.pathname.split('/')[1]]}
          // selectedKeys={[history.location.pathname]}
          mode="inline"
        >
          {/* <SubMenu key="line-bot" title="聊天機器人服務 (Line Bot)">
            <Menu.Item key="/line-bot/record">事件記錄管理</Menu.Item>
            <Menu.Item key="/line-bot/contract">和解書管理</Menu.Item>
            <Menu.Item key="/line-bot/account">會員管理</Menu.Item>
            <Menu.Item key="/line-bot/customer-entity">合作夥伴管理</Menu.Item>
            <Menu.Item key="/line-bot/license">序號管理</Menu.Item>
            <Menu.Item key="/line-bot/discount-code">活動折扣碼管理</Menu.Item>
          </SubMenu> */}
          <SubMenu key="web-admin" title="個人服務 (Web Individual Solution)">
            <Menu.Item key="/web-admin/record">事件記錄管理</Menu.Item>
            <Menu.Item key="/web-admin/contract">和解書管理</Menu.Item>
            <Menu.Item key="/web-admin/account">會員管理</Menu.Item>
            <Menu.Item key="/web-admin/license">序號管理</Menu.Item>
            <Menu.Item key="/web-admin/discount-code">活動折扣碼管理</Menu.Item>
            <Menu.Item key="/web-admin/order-log">交易列表</Menu.Item>
          </SubMenu>
          {role === 'admin' && <Menu.Item key="/admin">人員管理</Menu.Item>}
        </Menu>
      </Flex>
    </Sider>
  );
};

SideMenu.propTypes = {};

SideMenu.defaultProps = {};

export default SideMenu;
