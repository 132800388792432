import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  grid,
  flexbox,
  layout,
  space,
  color,
  border,
  position,
} from 'styled-system';

const Layout = styled.div`
  display: grid;
  ${grid}
  ${flexbox}
  ${layout}
  ${space}
  ${color}
  ${border}
  ${position}
`;

const Grid = (props) => {
  const { children } = props;
  return <Layout {...props}>{children}</Layout>;
};

Grid.propTypes = {
  children: PropTypes.node,
};

Grid.defaultProps = {
  children: null,
};

export default Grid;
