import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'react-router-dom';
import MainLayout from 'components/MainLayout';
import CommonRoute from './CommonRoute';

function AuthRoute({ component: Component, ...rest }) {
  const accessToken = localStorage.getItem('accessToken');
  const render = (props) => <Component {...props} />;
  if (accessToken) {
    return (
      <MainLayout>
        <CommonRoute {...rest} isLogined component={render} />
      </MainLayout>
    );
  }
  return <Redirect to="/" />;
}

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
};

export default AuthRoute;
