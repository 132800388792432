import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import CommonRoute from './CommonRoute';
import AuthRoute from './AuthRoute';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = () => (
  <Wrapper>
    <Spin spinning />
  </Wrapper>
);

// Login
const Login = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "login" */ 'pages/Login'
    ),
  { fallback: <Loading /> }
);

// Record

const RecordList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "record-list" */ 'pages/LineBot/Record/List'
    ),
  { fallback: <Loading /> }
);

const RecordDetail = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "record-detail" */ 'pages/LineBot/Record/Detail'
    ),
  { fallback: <Loading /> }
);

// Contract

const ContractList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "contract-list" */ 'pages/LineBot/Contract/List'
    ),
  { fallback: <Loading /> }
);

const ContractDetail = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "contract-detail" */ 'pages/LineBot/Contract/Detail'
    ),
  { fallback: <Loading /> }
);

// Account

const AccountList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "account-list" */ 'pages/LineBot/Account/List'
    ),
  { fallback: <Loading /> }
);

const EditAccount = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "edit-account" */ 'pages/LineBot/Account/Edit'
    ),
  { fallback: <Loading /> }
);

// CustomerEntity

const CustomerEntityList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "customer-entity-list" */ 'pages/LineBot/CustomerEntity/List'
    ),
  { fallback: <Loading /> }
);

const CreateCustomerEntity = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "create-customer-entity" */ 'pages/LineBot/CustomerEntity/Create'
    ),
  { fallback: <Loading /> }
);

const CustomerEntityDetail = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "customer-entity-detail" */ 'pages/LineBot/CustomerEntity/Detail'
    ),
  { fallback: <Loading /> }
);

// License

const LicenseList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "license-list" */ 'pages/LineBot/License/List'
    ),
  { fallback: <Loading /> }
);

const CreateLicense = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "create-license" */ 'pages/LineBot/License/Create'
    ),
  { fallback: <Loading /> }
);

const EditLicense = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "edit-license" */ 'pages/LineBot/License/Edit'
    ),
  { fallback: <Loading /> }
);

// DiscountCode

const DiscountCodeList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "discount-code-list" */ 'pages/LineBot/DiscountCode/List'
    ),
  { fallback: <Loading /> }
);

const CreateDiscountCode = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "create-discount-code" */ 'pages/LineBot/DiscountCode/Create'
    ),
  { fallback: <Loading /> }
);

// Admin

const AdminList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "admin-list" */ 'pages/Admin/List'
    ),
  { fallback: <Loading /> }
);

const CreateAdmin = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "create-admin" */ 'pages/Admin/Create'
    ),
  { fallback: <Loading /> }
);

const EditAdmin = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "edit-admin" */ 'pages/Admin/Edit'
    ),
  { fallback: <Loading /> }
);

const ChangePassword = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "change-password" */ 'pages/Admin/ChangePassword'
    ),
  { fallback: <Loading /> }
);

// WebAdmin

// Record

const WebAdminRecordList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "record-list" */ 'pages/WebAdmin/Record/List'
    ),
  { fallback: <Loading /> }
);

const WebAdminRecordDetail = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "record-detail" */ 'pages/WebAdmin/Record/Detail'
    ),
  { fallback: <Loading /> }
);

// Contract

const WebAdminContractList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "contract-list" */ 'pages/WebAdmin/Contract/List'
    ),
  { fallback: <Loading /> }
);

const WebAdminContractDetail = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "contract-detail" */ 'pages/WebAdmin/Contract/Detail'
    ),
  { fallback: <Loading /> }
);

// Account

const WebAdminAccountList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "account-list" */ 'pages/WebAdmin/Account/List'
    ),
  { fallback: <Loading /> }
);

const WebAdminCreateAccount = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "create-account" */ 'pages/WebAdmin/Account/Create'
    ),
  { fallback: <Loading /> }
);

const WebAdminEditAccount = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "edit-account" */ 'pages/WebAdmin/Account/Edit'
    ),
  { fallback: <Loading /> }
);

// License

const WebAdminLicenseList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "license-list" */ 'pages/WebAdmin/License/List'
    ),
  { fallback: <Loading /> }
);

const WebAdminCreateLicense = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "create-license" */ 'pages/WebAdmin/License/Create'
    ),
  { fallback: <Loading /> }
);

const WebAdminEditLicense = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "edit-license" */ 'pages/WebAdmin/License/Edit'
    ),
  { fallback: <Loading /> }
);

// DiscountCode

const WebAdminDiscountCodeList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "discount-code-list" */ 'pages/WebAdmin/DiscountCode/List'
    ),
  { fallback: <Loading /> }
);

const WebAdminCreateDiscountCode = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "create-discount-code" */ 'pages/WebAdmin/DiscountCode/Create'
    ),
  { fallback: <Loading /> }
);

const WebAdminEditDiscountCode = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "edit-discount-code" */ 'pages/WebAdmin/DiscountCode/Edit'
    ),
  { fallback: <Loading /> }
);

const WebAdminOrderLogList = loadable(
  () =>
    import(
      /*  webpackPrefetch: true, webpackChunkName: "order-log-list" */ 'pages/WebAdmin/OrderLog/List'
    ),
  { fallback: <Loading /> }
);

export default () => (
  <Switch>
    <CommonRoute key="login" exact path="/" component={Login} />
    <AuthRoute
      key="record"
      exact
      path="/line-bot/record"
      component={RecordList}
    />
    <AuthRoute
      key="record"
      exact
      path="/line-bot/record/:type/:id"
      component={RecordDetail}
    />
    <AuthRoute
      key="contract"
      exact
      path="/line-bot/contract"
      component={ContractList}
    />
    <AuthRoute
      key="contract"
      exact
      path="/line-bot/contract/:type/:id"
      component={ContractDetail}
    />
    <AuthRoute
      key="account"
      exact
      path="/line-bot/account"
      component={AccountList}
    />
    <AuthRoute
      key="account"
      exact
      path="/line-bot/account/:id/edit"
      component={EditAccount}
    />
    <AuthRoute
      key="customer-entity"
      exact
      path="/line-bot/customer-entity"
      component={CustomerEntityList}
    />
    <AuthRoute
      key="customer-entity"
      exact
      path="/line-bot/customer-entity/create"
      component={CreateCustomerEntity}
    />
    <AuthRoute
      key="customer-entity"
      exact
      path="/line-bot/customer-entity/:id"
      component={CustomerEntityDetail}
    />
    <AuthRoute
      key="license"
      exact
      path="/line-bot/license"
      component={LicenseList}
    />
    <AuthRoute
      key="license"
      exact
      path="/line-bot/license/create"
      component={CreateLicense}
    />
    <AuthRoute
      key="license"
      exact
      path="/line-bot/license/:id/edit"
      component={EditLicense}
    />
    <AuthRoute
      key="discount-code"
      exact
      path="/line-bot/discount-code"
      component={DiscountCodeList}
    />
    <AuthRoute
      key="discount-code"
      exact
      path="/line-bot/discount-code/create"
      component={CreateDiscountCode}
    />
    {/* WebAdmin */}
    <AuthRoute
      key="record"
      exact
      path="/web-admin/record"
      component={WebAdminRecordList}
    />
    <AuthRoute
      key="record"
      exact
      path="/web-admin/record/:id"
      component={WebAdminRecordDetail}
    />
    <AuthRoute
      key="contract"
      exact
      path="/web-admin/contract"
      component={WebAdminContractList}
    />
    <AuthRoute
      key="contract"
      exact
      path="/web-admin/contract/:id"
      component={WebAdminContractDetail}
    />
    <AuthRoute
      key="account"
      exact
      path="/web-admin/account"
      component={WebAdminAccountList}
    />
    <AuthRoute
      key="account"
      exact
      path="/web-admin/account/create"
      component={WebAdminCreateAccount}
    />
    <AuthRoute
      key="account"
      exact
      path="/web-admin/account/:id/edit"
      component={WebAdminEditAccount}
    />
    <AuthRoute
      key="license"
      exact
      path="/web-admin/license"
      component={WebAdminLicenseList}
    />
    <AuthRoute
      key="license"
      exact
      path="/web-admin/license/create"
      component={WebAdminCreateLicense}
    />
    <AuthRoute
      key="license"
      exact
      path="/web-admin/license/:id/edit"
      component={WebAdminEditLicense}
    />
    <AuthRoute
      key="discount-code"
      exact
      path="/web-admin/discount-code"
      component={WebAdminDiscountCodeList}
    />
    <AuthRoute
      key="discount-code"
      exact
      path="/web-admin/discount-code/create"
      component={WebAdminCreateDiscountCode}
    />
    <AuthRoute
      key="discount-code"
      exact
      path="/web-admin/discount-code/:id/edit"
      component={WebAdminEditDiscountCode}
    />
    <AuthRoute
      key="order-log"
      exact
      path="/web-admin/order-log"
      component={WebAdminOrderLogList}
    />
    <AuthRoute key="admin" exact path="/admin" component={AdminList} />
    <AuthRoute key="admin" exact path="/admin/create" component={CreateAdmin} />
    <AuthRoute key="admin" exact path="/admin/:id/edit" component={EditAdmin} />
    <AuthRoute
      key="admin"
      exact
      path="/admin/:id/change-password"
      component={ChangePassword}
    />
    <Redirect to="/" />
  </Switch>
);
